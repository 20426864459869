import React from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import LogoImg from '../images/logo.svg'
import WaveAnimation from '../animations/wave.json'

const FullWidthContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AnimationContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  padding: 16px;
  text-align: center;
`

const Logo = styled.img`
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`

const H1 = styled.h1`
  color: #FFFFFF;
  font-size: 48px;
  line-height: 130%;
  font-weight: 700;
  margin: 0 0 24px;

  @media (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    margin: 0 0 16px;
  }
`

  const P = styled.p`
  color: #FFFFFF;
  font-size: 20px;
  line-height: 140%;
  font-weight: 400;
  margin: 0 0 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 16px;
  }
`

const IndexPage = () => {
  return (
    <>
      <FullWidthContainer>
        <Container>
          <Logo src={LogoImg} alt="Panda Routine" />
          <H1>We are moving to a mobile platform.</H1>
          <P>Thanks for taking interest in our project! </P>
          <P>We have decided to take the next step and transfer Panda Routine to an improved mobile platform.</P>
          <P>Current user data is being migrated to the new application. You can reach out to our support team on help@pandaroutine.com with any concerns as usual.</P>
        </Container>
      </FullWidthContainer>
      <AnimationContainer>
        <Lottie animationData={WaveAnimation} loop />
      </AnimationContainer>
    </>
  )
}

export default IndexPage
